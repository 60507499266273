<template>
    <div>
        <section id="page-title" class="page-title-parallax page-title-dark" style="background-image: url('/assets/images/about/parallax.jpg'); padding: 120px 0;" data-bottom-top="background-position:0px 300px;" data-top-bottom="background-position:0px -300px;">

			<div class="container clearfix">
				<h1>Job Openings</h1>
				<span>Join our Fabulous Team of Intelligent Individuals</span>
				<ol class="breadcrumb">
					<li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
					<li class="breadcrumb-item active" aria-current="page">Jobs</li>
				</ol>
			</div>

		</section><!-- #page-title end -->
        <!-- Content
		============================================= -->
		<section id="content">
			<div class="content-wrap">
				<div class="container clearfix">
                    
					<div class="row col-mb-50">
                        <!-- sidebar -->
                        <div class="col-md-4">
                            <Sidebar/>
                        </div>
                        <!-- JD  -->
						<div class="col-md-8">
							<div>
                                <h3 style="width: 100%;padding: 0 0 0.75rem;border-bottom: 2px solid #1ABC9C;">Shopify Developer
                                <router-link to="/careers/shopify-developer/form" class="button button-3d m-0 button-primary" style="float:right">Apply Now</router-link></h3>
                                <h4>The Role</h4>
                                <p>We're looking for a <b>Shopify Developer</b> who is a solid team player who can work collaboratively directly with our US partner. This role will directly support our US partner, who has opened up his own Shopify consultancy firm. His business advises Shopify sellers to enhance their Shopify store to be more user-friendly, improve user experience, and develop digital marketing strategies to increase sales. He needs an experienced developer who can take his direction and help enhance, modify, and create customizations to his customers' existing Shopify stores</p>
                                <p>We know it can be tricky to apply for roles, wondering if the position is right for you and if you and your experience are suitable for the role. Many people won't apply for roles unless they feel that they tick every single box. At eFlex, we look for many different skills and abilities, and we're always looking for how new team members can add to eFlex and our culture. So if you don't think you quite meet all of the skills listed, we'd still love to hear from you!</p>

                                <div class="accordion accordion-bg">

                                    <div class="accordion-header">
                                        <div class="accordion-icon">
                                            <i class="accordion-closed icon-ok-circle"></i>
                                            <i class="accordion-open icon-remove-circle"></i>
                                        </div>
                                        <div class="accordion-title">
                                            What you'll be doing
                                        </div>
                                    </div>
                                    <div class="accordion-content">
                                        <ul class="iconlist iconlist-color mb-0">
                                            <li><i class="icon-ok"></i>Develop and implement new Features/ Pages within the Shopify platform</li>
                                            <li><i class="icon-ok"></i>Managing/developing custom implementations of various Shopify apps; both frontend and backend; including Bold Subscriptions, Bold Bundle, Google shopping, etc.</li>
                                            <li><i class="icon-ok"></i>Managing various e-commerce systems to ensure the continual operation and sales</li>
                                            <li><i class="icon-ok"></i>Coordinating with our US partner to assist them in supporting customers with order and subscription amendments, investigating and solving technical problems that arise</li>
                                            <li><i class="icon-ok"></i>Designing and implementing new website features and improving existing ones, such as the homepage, product page, etc.</li>
                                            <li><i class="icon-ok"></i>Updating the e-commerce catalog and feeds, including adding new products, configuring product bundles, creating discount codes & promotions to implement new stores and their functionalities for our top partner, always adhering to the platform's best practices and current web standards.</li>
                                            <li><i class="icon-ok"></i>Creating landing pages</li>
                                        </ul>
                                    </div>

                                    <div class="accordion-header">
                                        <div class="accordion-icon">
                                            <i class="accordion-closed icon-ok-circle"></i>
                                            <i class="accordion-open icon-remove-circle"></i>
                                        </div>
                                        <div class="accordion-title">
                                            Minimum Qualifications
                                        </div>
                                    </div>
                                    <div class="accordion-content">
                                        <ul class="iconlist iconlist-color mb-0">
                                            <li><i class="icon-ok"></i>Minimum of 3 years of experience as a Shopify developer</li>
                                            <li><i class="icon-ok"></i>Essential is to have experience with complex implementations such as Ajax Cart, Product Quick View, and Product Customizers, among others</li>
                                            <li><i class="icon-ok"></i>It is also necessary to understand advanced Shopify concepts such as the alternation of layouts and templates, product attributes, and cart properties, and a deep understanding of the limitations and possibilities of Shopify objects</li>
                                            <li><i class="icon-ok"></i>Advanced Vanilla Javascript and jQuery</li>
                                            <li><i class="icon-ok"></i>Needs to know Ruby on Rails</li>
                                            <li><i class="icon-ok"></i>Know how to write flawless and structured CSS</li>
                                            <li><i class="icon-ok"></i>Know how to layout HTML optimized for SEO and accessibility</li>
                                            <li><i class="icon-ok"></i>Deep knowledge of Shopify objects</li>
                                            <li><i class="icon-ok"></i>Comfortable communicating over Slack, Zoom, and email, as we are a fully remote team</li>
                                            <li><i class="icon-ok"></i>Nice to have - React/JSX expertise</li>
                                            <li><i class="icon-ok"></i>Nice to have - Vue/Vuex expertise</li>
                                        </ul>
                                    </div>
                                    
                                    <div class="accordion-header">
                                        <div class="accordion-icon">
                                            <i class="accordion-closed icon-ok-circle"></i>
                                            <i class="accordion-open icon-remove-circle"></i>
                                        </div>
                                        <div class="accordion-title">
                                            What you'll enjoy at eFlex
                                        </div>
                                    </div>
                                    <div class="accordion-content">
                                        <ul class="iconlist iconlist-color mb-0">
                                            <li><i class="icon-plus-sign"></i>We live our values as playmakers, obsessed with learning, care personally about our colleagues and partners, are radically open-minded, and take pride in everything we do.</li>
                                            <li><i class="icon-plus-sign"></i>We offer a Competitive Salary and Benefits package. This includes a comprehensive HMO and optical reimbursements.</li>
                                            <li><i class="icon-plus-sign"></i>A temporary work-from-home setup as we prepare to provide a safe environment for our employees who would like to work in the office. Our Future of Work plan is to move towards a hybrid work model (in-office & from home).</li>
                                            <li><i class="icon-plus-sign"></i>Recharge and go on your next getaway or simply take time off for yourself through our flexible personal & sick days. We want our team to be happy and healthy :)</li>
                                            <li><i class="icon-plus-sign"></i>We support our employee's career growth and development by offering opportunities for promotion.</li>
                                        </ul>
                                    </div>

                                    <div class="accordion-header">
                                        <div class="accordion-title">
                                            About eFlexervices
                                        </div>
                                    </div>
                                    <div class="accordion-content">
                                        <p>eFlexervices is a 23-year-old BPO company providing exceptional quality and unwavering trust. Our partnership approach to the business allows us to match the right talent to every organization we support. We invest in people to optimize performance and maximize efficiency. We work hard to produce the highest possible results for our partners.</p>
                                        <p>Performance is the underlying foundation that drives eFlexervices. We deliver the metrics our partners expect through proper recruitment and heavy investment in the right people.</p>
                                        <p>Deep integration within our partners' organizations drives our team to take ownership of their work. This stakeholder mindset pushes higher performance, better quality, and longer retention.</p>
                                    </div>
                                    <router-link to="/careers/shopify-developer/form" class="button button-3d m-0 button-primary" style="float:left">Apply Now</router-link>
                                </div>
                                <div class="divider divider-sm"></div>
                            </div>
						</div>
                        
                    </div>

				</div>
			</div>
		</section><!-- #content end -->

    </div>
</template>

<script>
import Sidebar from '@/components/CareerNav.vue'
export default {
    data() {
        return {
            
        }
    },
    components: {
        Sidebar
    },
    mounted() {
		window.scrollTo(0, 0)
	}
}
</script>